import { PrismicImage } from 'prismic/types/image';

import { imageAsCroppableImage, ViewportSizes } from 'utils/cleverCropping';

import { PrismicCroppablePicture } from './PrismicCroppablePicture';
import { PrismicPicture } from './PrismicPicture';

type Props = {
  image?: PrismicImage;
  width?: number;
  className?: string;
  componentSizes?: ViewportSizes;
  imagePosition?: {
    x?: number | undefined;
    y?: number | undefined;
  };
  lazy?: boolean;
};

export function RegularOrCroppableImage({
  image,
  width,
  componentSizes,
  imagePosition,
  className,
  lazy,
}: Props) {
  if (!image) {
    return null;
  }
  const possiblyCroppableImage = imageAsCroppableImage(image);

  if (possiblyCroppableImage && componentSizes) {
    return (
      <PrismicCroppablePicture
        image={possiblyCroppableImage}
        width={width}
        componentSizes={componentSizes}
        imagePosition={imagePosition}
        className={className}
        lazy={lazy}
      />
    );
  } else {
    return (
      <PrismicPicture
        image={image}
        width={width}
        imagePosition={imagePosition}
        className={className}
        lazy={lazy}
      />
    );
  }
}
