import classnames from 'classnames';

import { Container } from 'components/container/Container';

import { HTMLElementList } from 'types/html-types';

import s from './Section.module.scss';

const c = classnames.bind(s);

type SectionTheme = 'default' | 'gray';

type SectionProps = {
  children: React.ReactNode;
  container?: boolean;
  as?: HTMLElementList;
  className?: string;
  theme?: SectionTheme;
};

export const Section = ({
  children,
  container,
  theme = 'default',
  as = 'section',
  className,
}: SectionProps) => {
  const SectionEl = as;
  const content = container ? <Container>{children}</Container> : children;

  return (
    <SectionEl
      className={c(s.section, className, {
        [s[`theme-${theme}`]]: theme !== 'default',
      })}
    >
      {content}
    </SectionEl>
  );
};
