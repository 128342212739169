import {
  EducationSlicesNumbers,
  EducationSlicesNumbersDefaultItems,
  FrontpageSlicesNumbers,
  FrontpageSlicesNumbersDefaultItems,
  NewsSlicesNumbers,
  NewsSlicesNumbersDefaultItems,
  PageSlicesNumbers,
  PageSlicesNumbersDefaultItems,
  Power_StationSlicesNumbers,
  Power_StationSlicesNumbersDefaultItems,
} from 'prismic-types';

import { Numbers } from 'components/numbers/Numbers';
import { NumbersItem } from 'components/numbers/NumbersItem';
import { asText, RichText } from 'components/rich-text/RichText';

import { ExcludesFalse } from 'utils/excludesFalse';

type Props = {
  items: NumbersSliceItems;
};

export function NumbersSlice({ items }: Props) {
  return (
    <Numbers>
      {items
        .map((item, i) => {
          return (
            <NumbersItem
              key={i}
              label={asText(item.label)}
              from={item.from ?? undefined}
              to={item.number ?? undefined}
              unit={asText(item.unit)}
              green={item.green ?? false}
            >
              <RichText>{item.description}</RichText>
            </NumbersItem>
          );
        })
        .filter(Boolean as unknown as ExcludesFalse)}
    </Numbers>
  );
}
export type NumbersSlice =
  | PageSlicesNumbers
  | NewsSlicesNumbers
  | FrontpageSlicesNumbers
  | Power_StationSlicesNumbers
  | EducationSlicesNumbers;
export type NumbersSliceItems = Array<
  | PageSlicesNumbersDefaultItems
  | NewsSlicesNumbersDefaultItems
  | FrontpageSlicesNumbersDefaultItems
  | Power_StationSlicesNumbersDefaultItems
  | EducationSlicesNumbersDefaultItems
>;
export function prismicSliceToNumbers(s: NumbersSlice) {
  const items = s.variation?.items ?? [];

  if (items.length === 0) {
    return null;
  }
  return <NumbersSlice items={items} />;
}
