import { useInView } from 'react-intersection-observer'
import classNames from 'classnames/bind'

import { DateTime } from 'components/date/Date'
import { GridLayout, GridLayoutItem } from 'components/grid/GridLayout'
import { H1 } from 'components/heading/Heading'
import { Link } from 'components/link/Link'
import { MediaAnimationDirection } from 'components/media-animation/MediaAnimation'
import { PictureProps } from 'components/picture/Picture'
import { VideoProps } from 'components/video/Video'

import { HeadingTags } from 'types/html-types'

import { CtaMedia } from './CtaMedia'

import s from './Cta.module.scss'

const c = classNames.bind(s)

export type CtaProps = {
  title?: string
  date?: string
  headingType?: HeadingTags
  children?: React.ReactNode
  image?: React.ReactElement<PictureProps>
  video?: React.ReactElement<VideoProps>
  mediaPosition?: 'left' | 'right'
  link?: string
  linkText?: string
  secondaryLink?: string
  secondaryLinkText?: string
  animationDirection?: MediaAnimationDirection
  greenBoxColor?: string
}

export const Cta = ({
  title,
  date,
  headingType = 'h2',
  children,
  image,
  video,
  mediaPosition = 'left',
  link,
  linkText,
  animationDirection = 'topleft',
  secondaryLink,
  secondaryLinkText,
  greenBoxColor,
}: CtaProps) => {
  const { inView, ref } = useInView({ triggerOnce: true, threshold: 0.2 })
  const hasContent = title || children

  if (!hasContent) {
    return null
  }

  const contentColumns = { desktop: 5, wide: 5 }
  const contentOffset = {
    desktop: mediaPosition === 'left' ? 7 : undefined,
    wide: mediaPosition === 'left' ? 6 : 0,
  }

  return (
    <div ref={ref}>
      <GridLayout
        className={c(s.cta, {
          active: inView,
        })}
      >
        <CtaMedia
          video={video}
          image={image}
          mediaPosition={mediaPosition}
          animationDirection={animationDirection}
          greenBoxColor={greenBoxColor}
        />

        <GridLayoutItem
          columns={contentColumns}
          offset={contentOffset}
          className={s.cta__content}
        >
          {date && <DateTime dateString={date} />}
          {title && (
            <H1 className={s.cta__title} type={headingType}>
              {title}
            </H1>
          )}

          {children && <div className={s.cta__text}>{children}</div>}
          {link && (
            <div className={s.cta__linkWrap}>
              <Link className={s.cta__link} to={link}>
                {linkText ?? 'Skoða nánar'}
              </Link>
            </div>
          )}
          {secondaryLink && (
            <div className={s.cta__linkWrap}>
              <Link className={s.cta__link} to={secondaryLink}>
                {secondaryLinkText ?? 'Skoða nánar'}
              </Link>
            </div>
          )}
        </GridLayoutItem>
      </GridLayout>
    </div>
  )
}
