import React, { cloneElement, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames/bind'

import { PictureProps } from 'components/picture/Picture'
import { VideoProps } from 'components/video/Video'

import s from './MediaAnimation.module.scss'

const c = classNames.bind(s)

export type MediaAnimationDirection =
  | 'topleft'
  | 'topright'
  | 'bottomleft'
  | 'bottomright'
  | 'nogreenbox'

export type MediaAnimationProps = {
  children: React.ReactElement<PictureProps | VideoProps>
  className?: string
  width?: number
  height?: number
  active?: boolean
  animationDirection?: MediaAnimationDirection
  greenBoxColor?: string | undefined
}

export const MediaAnimation = ({
  children,
  className,
  width = 1,
  height = 1,
  active,
  animationDirection = 'topleft',
  greenBoxColor,
}: MediaAnimationProps) => {
  const { inView, ref } = useInView({ triggerOnce: true, threshold: 0.2 })
  return (
    <div
      className={c(s.mediaAnimation, className, s[animationDirection], {
        active: active !== undefined ? active : inView,
      })}
      style={{
        ...((greenBoxColor
          ? { '--media-animation-background': greenBoxColor }
          : {}) as React.CSSProperties),
      }}
      ref={ref}
    >
      <span
        className={s.mediaAnimation__ratio}
        style={{ '--ratio': height / width } as React.CSSProperties}
      />
      <div className={s.mediaAnimation__inner}>
        {cloneElement(children, { className: s.mediaAnimation__media })}
      </div>
    </div>
  )
}
