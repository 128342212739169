import CountUp from 'react-countup'
import classnames from 'classnames/bind'
import { InView } from 'react-intersection-observer'

import s from './NumbersItem.module.scss'
import { useState } from 'react'

const c = classnames.bind(s)

type IProps = {
  label?: string
  unit?: string
  from?: number
  to?: number
  active?: boolean
  children: React.ReactNode
  green?: boolean
}

const Unit = ({ unit }: { unit?: string }) => {
  if (!unit) {
    return null
  }

  if (unit.includes('CO2')) {
    const html = unit.replace('CO2', 'CO<sub>2</sub>')
    return <span dangerouslySetInnerHTML={{ __html: html }} />
  }

  return <>{unit}</>
}

export const NumbersItem = ({
  label,
  from = 0,
  to,
  unit,
  active,
  children,
  green,
}: IProps) => {
  const [countedUp, setCountedUp] = useState(false)

  const numberWithCommas = (n: number) => {
    const parts = n.toString().split('.')
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  return (
    <div className={c(s.numbersItem, { green })}>
      <div className={s.numbersItem__valueWrap}>
        <span className={s.numbersItem__label}>{label}</span>
        <CountUp
          end={to ?? 0}
          duration={2}
          formattingFn={numberWithCommas}
          redraw={false}
        >
          {({ countUpRef, start }) => (
            <InView
              threshold={0.5}
              onChange={(inView) => {
                if (inView && !countedUp) {
                  setCountedUp(true)
                  start()
                }
              }}
            >
              <span className={s.numbersItem__value} ref={countUpRef} />
            </InView>
          )}
        </CountUp>
        <span className={s.numbersItem__unit}>
          <Unit unit={unit} />
        </span>
      </div>
      <div className={s.numbersItem__text}>{children}</div>
    </div>
  )
}
