import { Children, cloneElement } from 'react';
import classnames from 'classnames/bind';
import { domAnimation, LazyMotion, m } from 'framer-motion';

import { staggerFadeInVariant } from 'utils/motion';

import s from './Numbers.module.scss';

const c = classnames.bind(s);

type Props = {
  title?: string | React.ReactNode;
  children: React.ReactNode;
};

const variants = staggerFadeInVariant(3);

export const Numbers = ({ title, children }: Props) => {
  const childArray = Children.toArray(children).filter(Boolean);

  if (!childArray.length) {
    return null;
  }

  return (
    <div className={c(s.numbers)}>
      <div className={s.numbers__inner}>
        {title && <h2 className={s.numbers__title}>{title}</h2>}
        <ul className={s.numbers__list}>
          {childArray.map((item, i) => (
            <LazyMotion features={domAnimation} key={i}>
              <m.li
                className={s.numbers__item}
                initial="hidden"
                custom={i}
                viewport={{ once: true, amount: 'all' }}
                variants={variants}
                whileInView="visible"
              >
                {cloneElement(item as React.ReactElement)}
              </m.li>
            </LazyMotion>
          ))}
        </ul>
      </div>
    </div>
  );
};
