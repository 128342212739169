import { CroppableImage, PrismicImage } from '@hugsmidjan_is/prismic/types'

import {
  Breakpoints,
  defaultBreakpoints,
  defaultImageSizes,
  ViewportSizes,
  WidthHeight,
} from 'utils/cleverCropping'
import { getFileExt } from 'utils/getFileExt'

import { Picture } from './Picture'

type Props = {
  image: CroppableImage
  width?: number
  className?: string
  lazy?: boolean
  componentSizes?: ViewportSizes
  componentBreakpoints?: Breakpoints
  imagePosition?: { x?: number; y?: number }
}

type MobileCroppableImage = CroppableImage & {
  focus_point_mobile: PrismicImage
}

function cropRectToUrl(
  imageUrl: string,
  rect: Array<number>,
  size: WidthHeight
) {
  return `${imageUrl}&rect=${rect[0]},${rect[1]},${rect[2]},${rect[3]}&w=${size.width}&h=${size.height}`
}

function removePossibleRect(imageUrl: string) {
  return imageUrl.split('&rect')[0]
}

function cropImageToAspect(
  image: CroppableImage,
  size: WidthHeight,
  mobile: boolean
) {
  let cropRect
  if ('focus_point_mobile' in image && mobile) {
    cropRect = (image as MobileCroppableImage).focus_point_mobile.url
  } else {
    cropRect = image?.focus_point?.url
  }
  cropRect = cropRect
    .split('&')[1]
    .replace('rect=', '')
    .split(',')
    .map((a) => {
      return parseInt(a)
    })

  const desieredAspect = size.width / size.height
  const possibleNewWidth = Math.ceil(desieredAspect * image.dimensions.height)
  const possibleNewHeight = Math.ceil(image.dimensions.width / desieredAspect)
  const newWidth =
    possibleNewWidth < image.dimensions.width
      ? possibleNewWidth
      : image.dimensions.width
  const newHeight =
    possibleNewHeight < image.dimensions.height
      ? possibleNewHeight
      : image.dimensions.height
  const widthGrowsBy = newWidth - cropRect[2]
  let newRectX = Math.ceil(cropRect[0] - widthGrowsBy / 2)
  if (image.dimensions.width < newRectX + newWidth) {
    newRectX = image.dimensions.width - newWidth
  }
  cropRect[0] = newRectX > 0 ? newRectX : 0
  cropRect[2] = newWidth

  const heighGrowsBy = newHeight - cropRect[3]
  let newRectY = Math.ceil(cropRect[1] - heighGrowsBy / 2)
  if (image.dimensions.height < newRectY + newHeight) {
    newRectY = image.dimensions.height - newHeight
  }
  cropRect[1] = newRectY > 0 ? newRectY : 0
  cropRect[3] = newHeight

  return cropRectToUrl(removePossibleRect(image.url), cropRect, size)
}

export const generateCroppedImageUrls = (
  image: CroppableImage,
  imageSizes: ViewportSizes = defaultImageSizes
) => {
  if ('x1' in imageSizes) {
    return { x1: cropImageToAspect(image, imageSizes.x1, false) }
  } else {
    const { wide, desktop, tablet, mobile } = imageSizes
    if (
      // if all image sizes are equal we don't need to print out a whole lot of the same image
      wide.width === desktop.width &&
      tablet.width === mobile.width
    ) {
      return { x1: cropImageToAspect(image, wide, false) }
    }

    return {
      wide: cropImageToAspect(image, wide, false),
      desktop: cropImageToAspect(image, desktop, false),
      tablet: cropImageToAspect(image, tablet, true),
      mobile: cropImageToAspect(image, mobile, true),
    }
  }
}

export function PrismicCroppablePicture({
  image,
  width,
  className,
  lazy,
  componentSizes = defaultImageSizes,
  componentBreakpoints = defaultBreakpoints,
  imagePosition,
}: Props) {
  if (!image || !image.url) {
    return null
  }

  const formats: Record<
    string,
    { wide?: string; desktop?: string; tablet?: string; mobile?: string }
  > = {}

  const extension = getFileExt(image.url) ?? 'jpg'

  const imageUrls = generateCroppedImageUrls(image, componentSizes)
  formats[extension] = imageUrls

  const src = imageUrls.x1 ?? `${image.url}&w=${width ?? 3000}`

  return (
    <Picture
      src={src ?? ''}
      alt={image.alt ?? ''}
      formats={formats}
      className={className}
      lazy={lazy}
      breakpoints={componentBreakpoints}
      imagePosition={imagePosition}
    />
  )
}
