import React from 'react'
import { linkResolver } from 'prismic/linkResolver'
import { PrismicImage } from 'prismic/types/image'
import { PrismicLink, PrismicLinkWithMeta } from 'prismic/types/link'
import { PrismicRichText } from 'prismic/types/richtext'
import {
  _FileLink,
  EducationSlicesCall_To_Action,
  NewsSlicesCall_To_Action,
  PageSlicesCall_To_Action,
  Power_StationSlicesCall_To_Action,
} from 'prismic-types'

import { Cta } from 'components/cta/Cta'
import { MediaAnimationDirection } from 'components/media-animation/MediaAnimation'
import { prismicPictureUrl } from 'components/picture/PrismicPicture'
import { RegularOrCroppableImage } from 'components/picture/RegularOrCroppablePicture'
import { asText, RichText } from 'components/rich-text/RichText'
import { Video } from 'components/video/Video'

import { generateImageSizes } from 'utils/cleverCropping'
import { titleHeadingTag } from 'utils/pageTitleFromSlices'

type Props = {
  title?: PrismicRichText
  date?: string
  link?: PrismicLinkWithMeta
  linkText?: PrismicRichText
  secondaryLink?: PrismicLinkWithMeta
  secondaryLinkText?: PrismicRichText
  image?: PrismicImage
  content?: PrismicRichText
  positionMediaRight: boolean
  animationDirection?: MediaAnimationDirection
  video?: PrismicLink
  green_box_color?: string
}

export function CallToActionSlice({
  title,
  date,
  image,
  video,
  link,
  linkText,
  content,
  positionMediaRight,
  animationDirection,
  secondaryLink,
  secondaryLinkText,
  green_box_color,
}: Props) {
  return (
    <Cta
      title={title ? asText(title) : undefined}
      headingType={titleHeadingTag(title)}
      date={date}
      image={
        image ? (
          <RegularOrCroppableImage
            image={image}
            componentSizes={generateImageSizes({
              ratio: 0.9,
              wide: 780,
              mobile: 680,
            })}
          />
        ) : undefined
      }
      video={
        video ? (
          <Video
            autoplay
            src={(video as _FileLink).url}
            poster={
              image
                ? prismicPictureUrl({ image: image, width: 780 })?.src
                : undefined
            }
          />
        ) : undefined
      }
      link={link ? linkResolver(link) : undefined}
      linkText={linkText ? asText(linkText) : undefined}
      mediaPosition={positionMediaRight ? 'right' : 'left'}
      animationDirection={animationDirection}
      secondaryLink={secondaryLink ? linkResolver(secondaryLink) : undefined}
      secondaryLinkText={
        secondaryLinkText ? asText(secondaryLinkText) : undefined
      }
      greenBoxColor={green_box_color}
    >
      {content && <RichText>{content}</RichText>}
    </Cta>
  )
}

const normalizeBoxPosition = (position: string) =>
  position.toLowerCase().replace(/\s/g, '') as MediaAnimationDirection

export type CallToActionSlice =
  | PageSlicesCall_To_Action
  | NewsSlicesCall_To_Action
  | Power_StationSlicesCall_To_Action
  | EducationSlicesCall_To_Action

export function prismicSliceToCallToAction(
  s: CallToActionSlice,
  date?: string
) {
  return (
    <CallToActionSlice
      title={(s.variation?.primary?.title ?? null) as PrismicRichText}
      date={date}
      image={(s.variation?.primary?.image ?? null) as PrismicImage}
      link={(s.variation?.primary?.link ?? null) as PrismicLinkWithMeta}
      linkText={(s.variation?.primary?.linkText ?? null) as PrismicRichText}
      secondaryLink={
        (s.variation?.primary?.secondary_link ?? null) as PrismicLinkWithMeta
      }
      video={(s.variation?.primary?.videoFile ?? null) as PrismicLinkWithMeta}
      secondaryLinkText={
        (s.variation?.primary?.secondary_link_text ?? null) as PrismicRichText
      }
      content={(s.variation?.primary?.content ?? null) as PrismicRichText}
      positionMediaRight={s.variation?.primary?.mediaPosition ?? false}
      animationDirection={normalizeBoxPosition(
        s.variation?.primary?.boxPosition ?? 'topleft'
      )}
      green_box_color={s.variation?.primary?.green_box_color ?? undefined}
    />
  )
}
