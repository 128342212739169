import { Element } from '@prismicio/richtext';
import { PrismicRichText } from 'prismic/types/richtext';
import {
  EducationSlices,
  NewsSlices,
  PageSlices,
  Power_StationSlices,
} from 'prismic-types';

import { HeadingTags } from 'types/html-types';

import { ExcludesFalse } from './excludesFalse';

type SliceWithTitle = {
  variation: {
    primary: {
      title: PrismicRichText;
    };
  };
};

// get all slices that have `title` in `variation.primary`
const sliceTitles = (
  slices: Array<PageSlices | NewsSlices | Power_StationSlices | EducationSlices>
): Array<SliceWithTitle> => {
  return slices.filter(
    (item): item is SliceWithTitle =>
      item.variation &&
      'primary' in item.variation &&
      item.variation.primary &&
      'title' in item.variation.primary &&
      item.variation.primary.title &&
      Array.isArray(item.variation.primary.title) &&
      'type' in item.variation.primary.title[0]
  );
};

// get first heading 1 in rich text
const getPageTitle = (text: PrismicRichText) => {
  const titles = text?.filter((item) => item.type === Element.heading1);
  return titles?.[0] ?? null;
};

export const pageTitleFromSlices = (
  slices: Array<PageSlices | NewsSlices | Power_StationSlices | EducationSlices>
) => {
  const slicesWithTitle = sliceTitles(slices);

  const heading1Titles = slicesWithTitle
    .map((item) => getPageTitle(item.variation.primary.title))
    .filter(Boolean as unknown as ExcludesFalse);

  //TODO: make this return a proper and not just use `as`
  return (heading1Titles[0] ? [heading1Titles[0]] : []) as PrismicRichText;
};

const elementToTag: Record<string, HeadingTags | undefined> = {
  [Element.heading1]: 'h1',
  [Element.heading2]: 'h2',
  [Element.heading3]: 'h3',
  [Element.heading4]: 'h4',
};

export function titleHeadingTag(title: PrismicRichText | null | undefined) {
  if (!title) {
    return undefined;
  }

  const heading = title[0]?.type ?? 'heading2';

  const headingTag = elementToTag[heading] ?? 'h2';
  return headingTag;
}
