// always a child of <Cta />, do not use elsewhere
import { cloneElement, useEffect } from 'react'
import classNames from 'classnames/bind'

import { GridLayoutItem } from 'components/grid/GridLayout'
import {
  MediaAnimation,
  MediaAnimationDirection,
} from 'components/media-animation/MediaAnimation'
import { PictureProps } from 'components/picture/Picture'
import { VideoProps } from 'components/video/Video'

import s from './Cta.module.scss'

const c = classNames.bind(s)

export type CtaMediaProps = {
  image?: React.ReactElement<PictureProps>
  video?: React.ReactElement<VideoProps>
  mediaPosition?: 'left' | 'right'
  animationDirection?: MediaAnimationDirection
  greenBoxColor?: string
}

export const CtaMedia = ({
  image,
  video,
  mediaPosition = 'left',
  animationDirection = 'topleft',
  greenBoxColor,
}: CtaMediaProps) => {
  // layout setup values
  const mediaLeft = mediaPosition === 'left'
  const mediaColumns = { tablet: 10, desktop: 5, wide: 5 }
  const mediaOffset = {
    tablet: 0,
    desktop: mediaLeft ? 1 : 6,
    wide: mediaLeft ? 0 : 6,
  }

  if (!video && !image) {
    return null
  }

  // Add extra padding to section if animationDirection topleft or topright
  const boxTop =
    animationDirection === 'topleft' || animationDirection === 'topright'

  // Add extra padding to section if animationDirection topleft or topright
  const boxBottom =
    animationDirection === 'bottomleft' || animationDirection === 'bottomright'

  return (
    <GridLayoutItem
      columns={mediaColumns}
      offset={mediaOffset}
      className={c(s.cta__mediaWrap, { boxTop, boxBottom })}
    >
      <MediaAnimation
        width={720}
        height={650}
        animationDirection={animationDirection}
        greenBoxColor={greenBoxColor}
      >
        {video
          ? cloneElement(video, {
              className: s.cta__media,
              autoplay: true,
              loop: true,
              addLayout: false,
            })
          : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            cloneElement(image!, { className: s.cta__media })}
      </MediaAnimation>
    </GridLayoutItem>
  )
}
