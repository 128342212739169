import { Children } from 'react';

import s from './ArticleSection.module.scss';

type Props = {
  children: React.ReactNode;
};

export const ArticleSection = ({ children }: Props) => {
  if (!Children.toArray(children).filter(Boolean).length) {
    return null;
  }

  return <section className={s.articleSection}>{children}</section>;
};
