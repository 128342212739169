import 'dayjs/locale/is';

import classnames from 'classnames/bind';
import dayjs from 'dayjs';

import s from './DateTime.module.scss';

const c = classnames.bind(s);

type Props = {
  dateString?: string;
  right?: boolean;
};

export const DateTime = ({ dateString, right = true }: Props) => {
  if (!dateString) {
    return null;
  }

  const date = dayjs(dateString);
  if (!date.isValid()) {
    return null;
  }

  return (
    <time className={c(s.date, right)} dateTime={date.format('YYYY-MM-DD')}>
      {date.locale('is').format('D. MMMM YYYY')}
    </time>
  );
};
