import { Variants } from 'framer-motion';

const staggerDelay = (i: number, batchSize: number, stagger = 0.15) => {
  return (i % batchSize) * stagger;
};

export const staggerFadeInVariant = (batchSize: number, extend?: Variants): Variants => {
  return {
    ...extend,
    visible: (i: number) => ({
      opacity: 1,
      transition: {
        duration: 0.7,
        delay: staggerDelay(i, batchSize),
      },
    }),
    hidden: {
      opacity: 0,
    },
  };
};
